moment.locale('en', {
    relativeTime: {
        future: "in %s",
        past:   "%s ago",
        s:  "seconds",
        m:  "%d minute",
        mm: "%d minutes",
        h:  "%d hour",
        hh: "%d hours",
        d:  "%d day",
        dd: "%d days",
        M:  "%d month",
        MM: "%d months",
        y:  "%d year",
        yy: "%d years"
    }
});